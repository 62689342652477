<template>
  <div class="root">
    <Nav></Nav>
    <router-view></router-view>
    <section id="contact" class="footer-banner">
      <div class="container">
        <div class="row over-hidden">
          <div class="col-4 footer-col-4">
            <div class="footer-cont">
              <h1
                class="footer-title wow bounceInDown"
                data-wow-duration="1.5s"
              >
                {{ $t("联系方式") }}
              </h1>
              <hr class="hr-line" />
              <div class="row footer-icon over-hidden">
                <img src="@/assets/pc/home/contact.png" alt="" />
              </div>
              <!-- <div class="row email-account">
                <div
                  class="col-6 no-padding footer-col-6 wow zoomIn"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                >
                  <div class="row account over-hidden">
                    <div class="col-2 no-padding">
                      <i class="vb-email"></i>
                    </div>
                    <div class="col-10 no-padding">
                      <p class="icon-title">E-mail</p>
                      <a href="javascript:void(0)" class="icon-subtitle"
                        >ng-cs@outlook.com</a
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 no-padding footer-col-6 wow zoomIn"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  <div class="row account over-hidden">
                    <div class="col-2 no-padding">
                      <i class="vb-skype"></i>
                    </div>
                    <div class="col-10 no-padding">
                      <p class="icon-title">Skype</p>
                      <a
                        target="_blank"
                        href="skype:live:ng-cs_1?chat"
                        class="icon-subtitle"
                        >ng-cs@outlook.com</a
                      >
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row email-account">
                <div
                  class="col-6 no-padding footer-col-6 wow zoomIn"
                  data-wow-duration="1s"
                  data-wow-delay=".6s"
                >
                  <div class="row account over-hidden">
                    <div class="col-2 no-padding">
                      <i class="vb-telegram"></i>
                    </div>
                    <div class="col-10 no-padding">
                      <p class="icon-title">Telegram</p>
                      <a
                        target="_blank"
                        href="https://t.me/NG_Tony"
                        class="icon-subtitle"
                        >@NG_Tony</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 footer-col-4">
            <div class="footer-links footer-cont">
              <h1
                class="footer-title wow bounceInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".8s"
              >
                {{ $t("快速链接") }}
              </h1>
              <hr class="hr-line" />
              <div class="links">
                <div
                  class="row wow slideInRight"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div class="col-5 no-padding footer-col-12">
                    <div class="quick-links">
                      <a href="/#home" class="text">
                        <el-icon style="margin-right: 20px" size="12"
                          ><ArrowRightBold /></el-icon
                        >{{ $t("主页") }}
                      </a>
                      <a href="/#products" class="text">
                        <el-icon style="margin-right: 15px" size="12"
                          ><ArrowRightBold
                        /></el-icon>
                        {{ $t("产品") }}
                      </a>
                      <a @click="listClick('services')" class="text">
                        <el-icon style="margin-right: 15px" size="12"
                          ><ArrowRightBold
                        /></el-icon>
                        {{ $t("服务") }}
                      </a>
                      <a @click="listClick('about')"  class="text">
                        <el-icon style="margin-right: 15px" size="12"
                          ><ArrowRightBold
                        /></el-icon>
                        {{ $t("关于我们") }}
                      </a>
                      <a @click="listClick('client')"  class="text">
                        <el-icon style="margin-right: 15px" size="12"
                          ><ArrowRightBold
                        /></el-icon>
                        {{ $t("联系我们") }}
                      </a>
                      <a @click="listClick('client')" class="text">
                        <el-icon style="margin-right: 15px" size="12"
                          ><ArrowRightBold
                        /></el-icon>
                        {{ $t("合作伙伴") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 no-padding footer-col-4">
            <div class="footer-about">
              <h1
                class="footer-title wow bounceInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
              >
                {{ $t("更多关于我们") }}
              </h1>
              <hr class="hr-line" />
              <p
                class="wow zoomIn"
                data-wow-duration="1s"
                data-wow-delay="1.2s"
              >
                {{ $t("home13") }}
              </p>
              <div
                class="footer-logo wow zoomIn"
                data-wow-duration="1s"
                data-wow-delay="1.2s"
              >
                <img src="@/assets/logo.png" alt="" />
                <p class="copyright">© 2024 M9 Gaming | All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";
let Nav = require("@/views/pc/components/Nav.vue").default;

import i18n from "@/locales/i18n";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: { Nav },
  data() {
    return {
      isIos: false,
      showDownload: true,
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
    if (localStorage.getItem("language")) {
      i18n.global.locale = localStorage.getItem("language");
    }
    localStorage.removeItem("form");
  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
  
    listClick(item) {
     
        if(this.$route.path!='/'){
          this.$router.push('/')
          setTimeout(() => {
            this.toElement(item);
          }, 50);
        }else {
          this.toElement(item);

        }
      
      // if(item.to){
      //   this.$router.push({
      //     name: item.to,
        
      //   });
      // }
    },
    toElement(element) {
      var scrollNum = this.getElementTop(element);
      document.documentElement.scrollTo({
        top: scrollNum - 100,
        behavior: "smooth",
      });
    },
    getElementTop(element) {
      var el =
        typeof element == "string" ? document.getElementById(element) : element;
      return el.offsetTop;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container {
  width: 1170px;
}
.footer-cont {
  border-right: 1px solid #e2e2e2;
  height: 300px;
}
.footer-banner {
  background: url(@/assets/pc/home/footer-bg.jpeg) center no-repeat;
  background-size: cover;
  width: 100%;
  padding: 40px 15px;
  .footer-about {
    margin-left: 30px;
    .footer-logo {
      max-width: 86%;
      margin-left: 78px;
      .copyright {
        font-size: 9px;
        margin: 0 14px;
        color: #e2e2e2;
      }
      > img {
        opacity: 0.8;
        max-width: 75%;
      }
    }
    > p {
      color: #e2e2e2;
    }
  }
  .footer-links {
    margin-left: 60px;
    .links {
      margin-top: 30px;
      overflow: hidden;
      .quick-links {
        margin-bottom: 3px;

        > a {
          text-decoration: none;
          color: #e2e2e2;
          display: list-item;
          padding: 3.5px 0;
          list-style: none;
        }
      }
    }
  }
  .email-account {
    margin-top: 11px;
    .account {
      .icon-subtitle {
        color: #e2e2e2;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
      color: #e2e2e2;
      .icon-title {
        margin: 0;
        font-size: 14px;
      }
    }
  }
  .footer-icon {
    width: 70%;
    font-size: 19px;
    overflow: hidden;
    margin: 36px 0 0 55px;
    img {
      width: 100%;
    }
  }
  .hr-line {
    text-align: left;
    width: 25px;
    background-color: #e2e2e2;
    margin: 20px 0;
    border: none;
    border-top: 1px solid #757575;
    border-bottom: 1px solid #757575;
  }
  .footer-title {
    color: #e2e2e2;
    font-size: 25px;
    margin: 5px 0;
  }
  .footer-cont {
    border-right: 1px solid #e2e2e2;
    height: 300px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-banner .footer-about .footer-logo .copyright {
    font-size: 8px;
    margin-left: 5px;
  }
}

@media (max-width: 991px) {
  hr {
    width: 500px;
    height: 2px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .footer-banner .footer-about > p {
    font-size: 12px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }
  hr {
    width: 400px;
    height: 2px;
    width: 350px;
    line-height: 1;
  }
}

@media (min-width: 576px) and (max-width: 1199px) {
  .footer-banner .footer-about .footer-logo {
    margin-left: 23px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .footer-banner .footer-links {
    margin-left: 30px;
  }
  .footer-banner .footer-title {
    font-size: 18px;
  }
}
@media (min-width: 576px) {
  .footer-col-12 {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .footer-banner .footer-about {
    margin: 0;
    .footer-logo {
      max-width: 70%;
      margin-left: 60px;
      .copyright {
        font-size: 7px;
        margin-left: 6px;
      }
      > img {
        max-width: 70%;
      }
    }
    > p {
      margin-top: 12px;
      font-size: 12px;
    }
  }
  .footer-banner .footer-links {
    margin: 0;
  }
  .footer-cont {
    border: 0;
    padding-bottom: 30px;
    height: auto;
  }
  .footer-col-4 {
    width: 100%;
  }
  .footer-banner .email-account .account .icon-subtitle {
    font-size: 10px;
  }
  .footer-banner .email-account .account .icon-title {
    font-size: 12px;
  }
  .footer-banner .email-account {
    margin: 0;
  }
  .container {
    width: 100%;
  }
  .footer-banner .footer-title {
    font-size: 18px;
  }

  .footer-banner {
    .footer-icon {
      width: 100%;
      margin: 16px 0;
      padding-left: 20px;
      img {
        width: 50%;
      }
    }
  }
  hr {
    width: 250px;
    height: 1px;
    line-height: 1;
  }

  .footer-cont {
    border: 0;
    padding-bottom: 30px;
    height: auto;
  }
}

.main-wrap {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 16px;
  width: 100%;
}
.fixBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.k-footer {
  background: #fff;
  padding: 30px 0;
  .copyright {
    color: #0d0c22;
    text-align: center;
  }
}
.root {
  background-color: #fff;
  //padding-bottom: 36px;
}
</style>

<style>
.canClick {
  cursor: pointer;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
}

.k-react-select .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: none !important;
}

.k-react-select .el-select .el-input__wrapper.is-focus {
  box-shadow: none !important;
  background-color: #f5f7f9;
}

.el-input__wrapper {
  background-color: #f5f7f9 !important;
  box-shadow: none !important;
}
.el-tag {
  background-color: rgb(35, 35, 32) !important;
  color: #fff !important;
}
.el-tag .el-tag__close {
  color: #fff !important;
}

.el-input__inner {
  height: 40px !important;
  color: #000 !important;
}
a:hover {
  color: #0091ea !important;
}
</style>