<template>
	<router-view></router-view>

</template>



<script>
	
	export default {
		name: 'app',
		components: {
			
		},
		provide () {
		   return {
					  scale:1
		   }
		 },
		 created(){
			
		 },
		methods: {
			
		}
	}
</script>

<style lang="scss">
	
*, :after, :before {
	box-sizing: border-box;
  }
  *{
	margin: 0;
    padding: 0;
  }

	html, body {
		margin: 0;
		padding: 0;
		 height: 100%;
		-webkit-font-smoothing: antialiased;
  	  color: #444;
		font-weight: 300;
	  }
	 
	  * {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		outline: none;
	}
	
	  #app {
		 height: 100%;
	  }
	  .flex_r{
		display:flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	  }
	  .flex_c{
		display:flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		
	  }
	  .canClick{
		cursor: pointer;
	  }
	  .flex_R{
		display:flex;
		flex-direction: row;
	  }
</style>
