import { createApp } from 'vue'
import './registerServiceWorker'
import store from './store'
import log from '@/utils/logger'
import i18n from './locales/i18n'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



import App from './App.vue'

import 'animate.css';
import 'animate.css/animate.compat.css';
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'  //此处就是wowjs中自带的animate的位置
const app = createApp(App)
app.config.globalProperties.$wow = wow

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
import tools from '@/utils/tools'
//import action from '@/components/core/action'
app.config.globalProperties.$tools = tools
//app.config.globalProperties.$act = action


app.use(store)
app.use(ElementPlus)

app.use(router);
app.use(i18n);


app.mount('#app')
