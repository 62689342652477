
let RootView = require('../views/pc/RootView.vue').default

let home = require('../views/pc/components/home.vue').default
let products = require('../views/pc/components/product.vue').default
let demo = require('../views/pc/Demo.vue').default

let rootChilren = [
	{
        path: '/',
        name: 'home',
        component: home
      },
	  {
        path: '/products',
        name: 'product',
        component: products
      }
	 
      
      
	
]

		
let routes = [
	{
		path: '/',
		name: 'root',
		redirect: '/home',
		component: RootView ,
		children: rootChilren
	},
	{
		path: '/demo',
		name: 'demo',
		component: demo
	},
	
	{
		  path: '/:pathMatch(.*)*',
		redirect:'/'
	}
]
export {
	routes,
} 